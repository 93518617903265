form {
    width: 90%;
    max-width: 300px;
    background-color: #35328C;
    padding: 33px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

label {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #F0F0EB;
    margin-bottom: 26px;
}

textarea {
    background-color: transparent;
    border:none;
    border-bottom: 2px solid #1BA0F2;
    box-shadow: 0px 5px 50px rgba(32,35,41, .25);
    color: #1e1E1F;
    font-size: 20px;
    text-align: center;
    padding: 12px;
    height: 96px;
    width: calc(100 - 25px);
    resize: none;
    color: #F0F0EB;
}

textarea::placeholder {
    color: #A5A5A5;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    font-weight: 400;
}

textarea:focus {
    outline: none;
}


.todo-form-button-container {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.todo-form-button {
    cursor:pointer;
    display: inline-block;
    font-size: 20px;
    color: #202329;
    font-weight: 400;
    width: 120px;
    height: 48px;
    border-radius: 2px;
    border: none;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

.todo-form-button-add {
    color: #F0F0EB;
    background-color: #1BA0F2;
}

.todo-form-button-cancel {
    color: #F0F0EB;
    background-color: transparent;
}

