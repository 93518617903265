.loading-container {
    position: relative;
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 24px;
    box-shadow: 0px 5px 50px rgba(32,35,41, 0.15);
    z-index:4;
}

.text {
    margin: 24px 0 24px 24px;
    width: calc(100% -120px);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}
.complete-icon, .delete-icon {
    border-radius: 50px;
    height: 48px;
    width: 48px;
}

.complete-icon {
    position: absolute;
    left: 12px;
}

.delete-icon {
    position: absolute;
    top: -24px;
    right: 20px;
    z-index:1;
}

.loading-container, .complete-icon, .delete-icon {
    background: linear-gradient(90deg, rgba(250,250,250,1), rgba(200,199,199));
    background-size: 400% 400%;
    animation: loading-animation 0.5s ease-in-out infinite;
}

@keyframes loading-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}