.create-todo-button {
  background-color: #16c60c;
  box-shadow: 0px 5px 25px rgba(22, 198, 12, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 50px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  font-weight: bold;
  color: #F0F0EB;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;

  transform: rotate(0);
  transition: 0.3s ease;
  z-index: 10;
}

.create-todo-button:hover {
  transform: rotate(224deg);
}
