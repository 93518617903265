body {
  margin: 0;
  padding: 0;
  background: #35328C;
  color: #F0F0EB;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

#root {
  margin: 0 24px;
  position: relative;
  min-height: 100vh;
}
