.todo-item {
    background-color: #F29F05;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.15);
    transition:all linear ;
  }

  .todo-item:hover {
    top: -5px;
    box-shadow: 0px 10px 50px rgba(32, 35, 41, .5);
  }

.todo-item::after {
  content: '';
  position: relative;
  top: -18px; /* adjust as requiured */
  left: 5px; /* adjust as required */
  width: 30px;
  height: 30px;
  background:#F29F05;
  display: block;
  border-radius: 15px;
  z-index: 3;
  }
  
  .todo-item-p {
    margin: 24px 0 24px 24px;
    width: calc(100% - 120px);
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
  .todo-item-p-complete {
    text-decoration: line-through;
  }
  
  .icon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .icon-check {
    position: absolute;
    left: 12px;
  }
  .icon-check-active {
    color: #4caf50;
  }

  .icon-pin {
    position: absolute;
    top: -1em;
    right: 0em;
    font-size: 20pt;
    border-spacing: 5px;
    transition: all ease-in-out 0.5s;
    z-index: 2;
  }

  .icon-pin:hover {
    transform: translate(5px, -10px);
  }


