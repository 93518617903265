.todo-search {
  background: transparent;
  border: none;
  border-bottom: 2px solid #1BA0F2;
  color: #F0F0EB;
  /* border: 2px solid #202329; */
  margin: 0 24px;
  height: 64px;
  width: calc(100% - 62px);
  font-size: 24px;
  text-align: center;
  font-family: "Montserrat";
  font-weight: 400;
  box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.25);
}

.todo-search::placeholder {
  color: #F0F0EB;
  font-family: "Montserrat";
  font-weight: 400;
}

.todo-search:focus {
  outline: none;
}
